import activity from './m-sponsor-link.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Sponsor Link',
  icon: 'mdi-content-copy',
  description: 'Copy & share your sponsor link with participants',
  status: true,
  styling: {
    borderColor: '#ea6764'
  },
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-sponsor-link',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        link: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
